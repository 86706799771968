import React from 'react';

import { gql, useQuery } from '@apollo/client';
import { BrokenImage, SaveAlt } from '@mui/icons-material';
import { Button, Box, Container, Stack, Typography, Snackbar } from '@mui/material';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingSpinner, TopupResultCard } from '../../components';
import { GOLDEN_RATIO } from '../../config';
import { TopupStatus } from '../../components/topup_result_card';

const getGbPrimePayQrCodeGql = gql(`
  query getGbPrimePayQrCode($satang: Int!, $isTaxInvoice: Boolean) {
    getGbPrimePayQrCode(satang: $satang, isTaxInvoice: $isTaxInvoice) {
      success
      qrCodeBase64Image
      referenceId
    }
  }
`);

const topUpStatusGql = gql(`
  query topUpStatus($referenceId: ID!) {
    topupStatus(referenceId: $referenceId) {
      success
      status
    }
  }
`);

interface QrCodeData {
  getGbPrimePayQrCode: {
    success: boolean;
    qrCodeBase64Image: string;
    referenceId: string;
  };
}

interface TopUpStatusData {
  topupStatus: {
    success: boolean;
    status: string;
  };
}

const CheckOutQrPage: React.FC = () => {
  const { state } = useLocation() as { state?: { amount?: number; isTaxInvoice?: boolean } };
  const [topupStatus, setTopupStatus] = React.useState<TopupStatus>('pending');
  const [error, setError] = React.useState<string | null>(null);
  const navigate = useNavigate();

  const {
    data: qrCodeData,
    loading: qrCodeLoading,
    error: qrCodeError,
  } = useQuery<QrCodeData>(getGbPrimePayQrCodeGql, {
    fetchPolicy: 'network-only',
    variables: {
      satang: (state?.amount ?? 0) * 100,
      isTaxInvoice: state?.isTaxInvoice,
    },
    skip: isNil(state?.amount),
  });

  const {
    data: topupData,
    loading: topupLoading,
    error: topupError,
    startPolling: topupStartPolling,
    stopPolling: topupStopPolling,
  } = useQuery<TopUpStatusData>(topUpStatusGql, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { referenceId: qrCodeData?.getGbPrimePayQrCode.referenceId },
    skip: !qrCodeData?.getGbPrimePayQrCode.referenceId,
  });

  React.useEffect(() => {
    if (qrCodeData?.getGbPrimePayQrCode.referenceId) {
      topupStartPolling(GOLDEN_RATIO * 5000);
    }
    return () => {
      topupStopPolling();
    };
  }, [qrCodeLoading]);

  React.useEffect(() => {
    if (!topupData?.topupStatus?.status) return;

    if (topupData.topupStatus.status === 'complete') {
      topupStopPolling();
      setTopupStatus('complete');
    } else if (topupData.topupStatus.status === 'error') {
      topupStopPolling();
      setTopupStatus('error');
    }

    return () => {
      topupStopPolling();
    };
  }, [topupData?.topupStatus?.status]);

  const saveQrHandler = () => {
    if (!qrCodeData?.getGbPrimePayQrCode.qrCodeBase64Image) {
      setError('No QR code image available');
      return;
    }
    const base64Str = `data:image/png;base64,${qrCodeData.getGbPrimePayQrCode.qrCodeBase64Image}`;
    const filename = `TopUp_${dayjs().format('YYYYMMDD')}_${qrCodeData.getGbPrimePayQrCode.referenceId}.png`;

    const downloadElement = document.createElement('a');
    downloadElement.href = base64Str;
    downloadElement.download = filename;
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
  };

  if (qrCodeLoading) return <LoadingSpinner />;
  if (qrCodeError)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error" align="center">
          Error loading QR code: {qrCodeError.message}
        </Typography>
      </Box>
    );

  return (
    <Container maxWidth="sm" sx={{ padding: 2 }}>
      {topupStatus === 'pending' ? (
        <Stack alignItems="center" gap={2}>
          {isNil(qrCodeData?.getGbPrimePayQrCode.qrCodeBase64Image) ? (
            <Stack width="100%" height={300} justifyContent="center" alignItems="center" gap={2}>
              <BrokenImage fontSize="large" color="action" />
              <Typography variant="body1" color="GrayText">
                ไม่มีข้อมูล
              </Typography>
            </Stack>
          ) : (
            <img
              src={`data:image/png;base64,${qrCodeData?.getGbPrimePayQrCode.qrCodeBase64Image}`}
              alt="Payment QR Code"
              style={{ imageRendering: 'crisp-edges', height: '70vh', width: 'auto' }}
            />
          )}
          <Button
            variant="contained"
            size="large"
            fullWidth
            disabled={isNil(qrCodeData?.getGbPrimePayQrCode.qrCodeBase64Image)}
            startIcon={<SaveAlt />}
            onClick={saveQrHandler}
          >
            บันทึกรูป
          </Button>
        </Stack>
      ) : (
        <Stack alignItems="center">
          <Box sx={{ width: '75%' }}>
            <TopupResultCard topupStatus={topupStatus} />
            <Box sx={{ paddingRight: 3, paddingLeft: 3 }}>
              <Button variant="contained" fullWidth color="primary" sx={{ mt: 1 }} onClick={() => navigate('/')}>
                กลับหน้าหลัก
              </Button>
            </Box>
          </Box>
        </Stack>
      )}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)} message={error} />
    </Container>
  );
};

export default CheckOutQrPage;
