import React from 'react';

import type { FallbackProps } from 'react-error-boundary';
import { get } from 'lodash';
import ErrorMessageBox from './error_message_box';
import { createScopedI18n } from '../i18n/i18n';

const errorPageI18n = createScopedI18n('pages.error');

const ErrorFallback = ({ error }: FallbackProps) => {
  const errorMessage = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let errorMessage = typeof error === 'string' ? error : get(error, 'message');

    if (errorMessage === 'Failed to fetch' || errorMessage === 'Connection problem') {
      errorMessage = errorPageI18n('error_503');
    }

    return errorMessage;
  }, [error]);

  return <ErrorMessageBox errorMessage={errorMessage} />;
};

export default ErrorFallback;
