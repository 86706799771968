import React from 'react';

import { Box } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { Navigate, RouterProvider, ScrollRestoration, createBrowserRouter } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { ApolloErrorThrower, LoadingSpinner, useSetAnalyticUserProperties } from '../components';
import { AppContext } from '../contexts/app_context';
import tokenManager from '../utils/token_manager';

import { AuthLayout } from '../pages/auth/layout';
import { ProfileEditLayout } from '../pages/profile/layout';

import RootError from '../pages/error';
import RootLayout from '../pages/layout';

import { IsFindTempUserGuard } from './is_find_temp_user_guard';
import { IsForceChangePasswordGuard } from './is_force_change_password_guard';
import { IsHaveRestaurantGuard } from './is_have_restaurant_guard';
import { IsInfoCompleteGuard } from './is_info_complete_guard';
import { IsLoginGuard } from './is_login_guard';
import { IsPdpaGuard } from './is_pdpa_guard';
import { LoggedInRedirectGuard } from './logged_in_redirect_guard';
import { useLoadInfoCompleteStatusContext } from './use_load_info_complete_status_context';
import { useLoadRestaurantsContext } from './use_load_restaurants_context';
import { useLoadUserInfoContext } from './use_load_user_info_context';

import { RegisterPage } from '../pages/auth/register';
import { ResetPasswordPage } from '../pages/auth/reset_password';
import { SignInPage } from '../pages/auth/sign_in';
import { ThankYouPage } from '../pages/auth/thank_you';

import { ForceChangePasswordPage } from '../pages/force_change_password';
import { PdpaAgreementPage } from '../pages/pdpa_agreement';

import CheckOutCreditCardPage from '../pages/credit/check_out_credit_card';
import CheckOutCreditCardPendingPage from '../pages/credit/check_out_credit_card_pending';
import CheckOutQrPage from '../pages/credit/check_out_qr';
import { CreditPage } from '../pages/credit/page/page';
import { CreditPurchasePage } from '../pages/credit/purchase/page';

import { JobDetailLayout } from '../pages/jobs/[id]/layout';
import JobCreatePage from '../pages/jobs/new';
import { JobsLayout } from '../pages/jobs/page/layout';

import JobHiringsPage from '../pages/job_hirings/page/page';

import { NotificationsPage } from '../pages/notifications';

import { ChangePasswordPage } from '../pages/profile/change_password/page';
import { ProfileEditPage } from '../pages/profile/edit';
import { ProfilePage } from '../pages/profile/page';

import { ContactUsPage } from '../pages/contact_us/page';

import { RestaurantsEditPage } from '../pages/restaurants/[id]/edit';
import { RestaurantsDetailPage } from '../pages/restaurants/[id]/page';
import { RestaurantsNewPage } from '../pages/restaurants/new';
import { RestaurantsPage } from '../pages/restaurants/page';

import { ChangePhoneNumberPage } from '../pages/change_phone_number';
import { SignOutPage } from '../pages/sign_out';

export const rootRouter = createBrowserRouter([
  {
    path: '/pdpa_agreement',
    element: (
      <IsLoginGuard>
        <IsFindTempUserGuard>
          <ScrollRestoration />
          <ProfileEditLayout />
          <PdpaAgreementPage />
        </IsFindTempUserGuard>
      </IsLoginGuard>
    ),
  },
  {
    path: '/force_change_password',
    element: (
      <IsLoginGuard>
        <IsFindTempUserGuard>
          <ScrollRestoration />
          <ProfileEditLayout />
          <ForceChangePasswordPage />
        </IsFindTempUserGuard>
      </IsLoginGuard>
    ),
  },
  {
    path: '/first_profile_edit',
    element: (
      <IsLoginGuard>
        <IsFindTempUserGuard>
          <IsPdpaGuard>
            <IsForceChangePasswordGuard>
              <ScrollRestoration />
              <ProfileEditLayout />
              <ProfileEditPage />
            </IsForceChangePasswordGuard>
          </IsPdpaGuard>
        </IsFindTempUserGuard>
      </IsLoginGuard>
    ),
  },
  {
    path: '/first_new_restaurant',
    element: (
      <IsLoginGuard>
        <IsFindTempUserGuard>
          <IsPdpaGuard>
            <IsInfoCompleteGuard>
              <ScrollRestoration />
              <RestaurantsNewPage />
            </IsInfoCompleteGuard>
          </IsPdpaGuard>
        </IsFindTempUserGuard>
      </IsLoginGuard>
    ),
  },
  {
    path: '/flow_sign_out',
    element: (
      <IsLoginGuard>
        <Box display="flex" height="100vh" flexDirection="column">
          <ScrollRestoration />
          <SignOutPage />
        </Box>
      </IsLoginGuard>
    ),
  },
  {
    path: '/',
    element: (
      <IsLoginGuard>
        <IsFindTempUserGuard>
          <ApolloErrorThrower>
            <IsPdpaGuard>
              <IsForceChangePasswordGuard>
                <IsInfoCompleteGuard>
                  <IsHaveRestaurantGuard>
                    <ScrollRestoration />
                    <RootLayout />
                  </IsHaveRestaurantGuard>
                </IsInfoCompleteGuard>
              </IsForceChangePasswordGuard>
            </IsPdpaGuard>
          </ApolloErrorThrower>
        </IsFindTempUserGuard>
      </IsLoginGuard>
    ),
    errorElement: <RootError />,
    children: [
      { path: 'change_phone_number', element: <ChangePhoneNumberPage /> },
      {
        path: 'credit',
        children: [
          { index: true, element: <CreditPage /> },
          { path: 'purchase', element: <CreditPurchasePage /> },
          { path: 'check_out_qr', element: <CheckOutQrPage /> },
          { path: 'check_out_credit_card', element: <CheckOutCreditCardPage /> },
          { path: 'check_out_credit_card_pending', element: <CheckOutCreditCardPendingPage /> },
        ],
      },
      {
        path: '/topup_result',
        element: (
          <Navigate
            to={'/credit/check_out_credit_card_pending'}
            replace
            state={{ referenceId: new URLSearchParams(window.location.search).get('referenceNo') }}
          />
        ),
      },
      {
        path: 'jobs',
        children: [
          {
            path: '',
            element: <JobsLayout />,
            children: [{ path: ':id', element: <JobDetailLayout /> }],
          },
          { path: 'new', element: <JobCreatePage /> },
        ],
      },
      { path: 'job_hirings', element: <JobHiringsPage /> },
      { path: 'reset_password', element: <ResetPasswordPage /> },
      {
        path: 'restaurants',
        children: [
          { index: true, element: <RestaurantsPage /> },
          {
            path: ':id',
            children: [
              { index: true, element: <RestaurantsDetailPage /> },
              { path: 'edit', element: <RestaurantsEditPage /> },
            ],
          },
          { path: 'new', element: <RestaurantsNewPage /> },
        ],
      },
      { path: 'notifications', element: <NotificationsPage /> },
      {
        path: 'profile',
        children: [
          { index: true, element: <ProfilePage /> },
          { path: 'edit', element: <ProfileEditPage /> },
          { path: 'change_password', element: <ChangePasswordPage /> },
        ],
      },
      { path: 'contact_us', element: <ContactUsPage /> },

      { path: 'sign_out', element: <SignOutPage /> },

      { path: '/', element: <Navigate to="jobs" replace /> },
    ],
  },
  {
    path: '/auth',
    element: (
      <LoggedInRedirectGuard>
        <ScrollRestoration />
        <AuthLayout />
      </LoggedInRedirectGuard>
    ),
    children: [
      { path: 'sign_in', element: <SignInPage /> },
      { path: 'reset_password', element: <ResetPasswordPage /> },
      { path: 'register', element: <RegisterPage /> },
      { path: 'thank_you', element: <ThankYouPage /> },
    ],
  },
]);

const RootRouter = () => {
  const { showBoundary } = useErrorBoundary();
  const { contextIsLogin, setContextIsLogin } = React.useContext(AppContext);

  const [isLoginLoading, setIsLoginLoading] = React.useState(true);
  React.useEffect(() => {
    const checkToken = async () => {
      setIsLoginLoading(true);
      try {
        const token = await tokenManager.getToken();
        setContextIsLogin(!isEmpty(token));
      } catch (error) {
        const isInvalidToken =
          get(error, 'message') === 'access_token.invalid' ||
          get(error, 'response.status') === 401 ||
          get(error, 'name') === 'InvalidTokenError';

        if (isInvalidToken) {
          setContextIsLogin(false);
        } else {
          // Other error (Network etc.) Send to ErrorBoundary
          showBoundary(error);
        }
      }
      // Wait info hook to start fetching and setLoading
      setTimeout(() => setIsLoginLoading(false), 50);
    };

    checkToken();
  }, [setContextIsLogin]);

  useSetAnalyticUserProperties();

  const { loading: infoCompleteLoading } = useLoadInfoCompleteStatusContext({ skip: !contextIsLogin });
  const { loading: restaurantsLoading } = useLoadRestaurantsContext({ skip: !contextIsLogin });
  const { loading: userInfoLoading } = useLoadUserInfoContext({ skip: !contextIsLogin });

  if (isLoginLoading || infoCompleteLoading || restaurantsLoading || userInfoLoading) {
    return <LoadingSpinner BoxProps={{ height: '100vh' }} />;
  }

  return <RouterProvider router={rootRouter} />;
};

export default RootRouter;
