import React from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import { map } from 'lodash';
import { AppContext } from '../contexts/app_context';
import { useGetPermissions } from '../components';

const getRestaurantsGql = gql(`
  query getRestaurants {
    user {
      id
      restaurants {
        id
        name
        branchName
        businessType
        frontPhotoUri
        isInfoCompletedV2
      }
    }
  }
`);

const useLoadRestaurantsContext = ({ skip }: { skip?: boolean } = {}) => {
  const { setContextRestaurants, setContextPermissions, contextUser } = React.useContext(AppContext);
  const { getChainPermissions, getRestaurantPermissions } = useGetPermissions();

  const [isLoading, setIsLoading] = React.useState(true);
  const [getRestaurantsQuery, { loading }] = useLazyQuery(getRestaurantsGql, {
    fetchPolicy: 'network-only',
  });

  const fetchRestaurantsAndPermissions = React.useCallback(
    async (updateContext = true) => {
      const { data } = await getRestaurantsQuery();
      let nextRestaurants = [];
      if (data?.user.restaurants) {
        nextRestaurants = map(data.user.restaurants, ({ isInfoCompletedV2, ...restaurant }) => ({
          ...restaurant,
          isInfoComplete: isInfoCompletedV2,
        }));
        if (updateContext) {
          setContextRestaurants(nextRestaurants);
        }
      }

      if (contextUser?.chain?.id) {
        const chainPermissions = await getChainPermissions(contextUser.chain.id);
        if (chainPermissions?.chain) {
          setContextPermissions((prev) => ({ ...prev, chain: chainPermissions.chain }));
        }
      }

      if (nextRestaurants.length > 0) {
        const restaurantPermissions = await getRestaurantPermissions(nextRestaurants[0].id);
        if (restaurantPermissions?.restaurant) {
          setContextPermissions((prev) => ({ ...prev, restaurant: restaurantPermissions.restaurant }));
        }
      }

      return nextRestaurants;
    },
    [getRestaurantsQuery, setContextRestaurants, contextUser],
  );

  React.useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      await fetchRestaurantsAndPermissions();
      setIsLoading(false);
    };

    if (!skip) {
      fetch();
    } else {
      setIsLoading(false);
    }
  }, [fetchRestaurantsAndPermissions, skip]);

  return { loading: loading || isLoading, fetchRestaurantsAndPermissions };
};

export { useLoadRestaurantsContext };
