import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
} from '@mui/material';
import type { ButtonProps } from '@mui/material';
import { AutoFixNormal } from '@mui/icons-material';
import Analytic from '../utils/analytic';
import { AppContext } from '../contexts/app_context';

type JoinAIButtonProps<C extends React.ElementType> = ButtonProps<C, { component?: C }> & {
  placement?: string;
  jobType?: string;
};

export const JoinAIButton = <C extends React.ElementType>({
  placement,
  jobType,
  children,
  displayType = 'BUTTON',
  ...props
}: JoinAIButtonProps<C>) => {
  const { contextCurrentRestaurant, contextUser } = React.useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleClick = () => {
    setOpen(true);
    Analytic().logEvent('join_ai_auto_match', {
      restaurant_id: contextCurrentRestaurant?.id ?? 'unknown',
      user_id: contextUser?.id ?? 'unknown',
      placement,
      job_type: jobType,
      timestamp: new Date(),
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    if (!isChecked) {
      setOpen(true);
      Analytic().logEvent('join_ai_auto_match', {
        restaurant_id: contextCurrentRestaurant?.id ?? 'unknown',
        user_id: contextUser?.id ?? 'unknown',
        placement,
        job_type: jobType,
        timestamp: new Date(),
      });
    }
  };

  return true ? (<></>) : (
    <>
      {displayType === 'BUTTON' ? (
        <Button startIcon={<AutoFixNormal />} onClick={handleClick} {...props}>
          คัดเลือก TEMP ด้วย AI Auto Match
        </Button>
      ) : (
        <FormControl sx={{ mt: 1, mb: 4 }} component="fieldset" variant="standard">
          <FormControlLabel
            control={<Checkbox name="AI" checked={isChecked} onChange={handleCheckboxChange} />}
            label={
              <Box display="flex" alignItems="center">
                <Typography color={'primary'}>คัดเลือก TEMP ด้วย AI Auto Match</Typography>
                <AutoFixNormal color={'primary'} sx={{ ml: 1 }} />
              </Box>
            }
          />
        </FormControl>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>AI Auto Match</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '10px',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              whiteSpace: { md: 'nowrap' },
              maxWidth: { xs: '250px', sm: '350px', md: '700px' },
            }}
          >
            ระบบได้เพิ่มคุณเข้าไปในกลุ่มผู้สมัครทดลองใช้งาน
            <br />
            หมายเหตุ: ฟีเจอร์นี้ยังไม่พร้อมใช้งานในขณะนี้ กรุณาคัดเลือกผู้สมัครด้วยตนเองเช่นเดิม
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            ตกลง
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
