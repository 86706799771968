import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { isEmpty, isNil, map, toNumber } from 'lodash';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { TimePicker, TimePickerProps, TimeValidationError } from '@mui/x-date-pickers';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';

const DEFAULT_AMPM = false;
const DEFAULT_TIME_STEPS_HOURS = 1;
const DEFAULT_TIME_STEPS_MINUTES = 15;

export type TimePickerShorthand = { offsetMinute: number; label: string };
export interface TimePickerWithShorthandProps<TDate> extends TimePickerProps<TDate> {
  shorthands?: TimePickerShorthand[];
}

export type TimePickerValue = Dayjs | null;

/**
 * This code creates a custom TimePickerWithShorthand component extending @mui/x-date-pickers TimePicker,
 * providing shorthands for quick time selection via a RadioGroup and specific props.
 */
const TimePickerWithShorthand = ({
  shorthands,
  value,
  timeSteps,
  ampm,
  onAccept,
  onChange,
  ...timePickerProps
}: TimePickerWithShorthandProps<TimePickerValue>) => {
  const [radioGroupValue, setRadioGroupValue] = React.useState<number>(0);
  const [timePickerValue, setTimePickerValue] = React.useState<TimePickerValue | undefined>(value);

  const update = (datetime: TimePickerValue) => {
    const minutes = dayjs(datetime).diff(value, 'minutes');
    setRadioGroupValue(minutes);
    setTimePickerValue(datetime);
  };

  const handleRadioGroupChange = (minutes: number) => {
    if (!Number.isFinite(minutes)) return;
    const datetime = dayjs(value).add(minutes, 'minutes');
    update(datetime);
    if (!isNil(onAccept)) onAccept(datetime);
    if (!isNil(onChange)) onChange(datetime, { validationError: null });
  };

  const handleTimePickerAccept = (datetime: TimePickerValue) => {
    if (isNil(datetime)) return;
    update(datetime);
    if (!isNil(onAccept)) onAccept(datetime);
  };

  const handleTimePickerChange = (
    datetime: TimePickerValue,
    context: PickerChangeHandlerContext<TimeValidationError>,
  ) => {
    if (isNil(datetime)) return;
    update(datetime);
    if (!isNil(onChange)) onChange(datetime, context);
  };

  return (
    <>
      {!isNil(shorthands) && !isEmpty(shorthands) ? (
        <RadioGroup
          value={radioGroupValue}
          onChange={(_, selectedValue) => handleRadioGroupChange(toNumber(selectedValue))}
          style={{ marginBottom: 12 }}
        >
          {map(shorthands, ({ offsetMinute, label }) => (
            <FormControlLabel value={offsetMinute} label={label} control={<Radio />} />
          ))}
        </RadioGroup>
      ) : null}
      <TimePicker
        value={timePickerValue}
        onAccept={handleTimePickerAccept}
        onChange={handleTimePickerChange}
        ampm={ampm !== undefined ? ampm : DEFAULT_AMPM}
        timeSteps={{ hours: DEFAULT_TIME_STEPS_HOURS, minutes: DEFAULT_TIME_STEPS_MINUTES, ...timeSteps }}
        minutesStep={DEFAULT_TIME_STEPS_MINUTES}
        {...timePickerProps}
      />
    </>
  );
};

export default TimePickerWithShorthand;
