import React from 'react';

import { join, max } from 'lodash';
import { Avatar, Stack, Typography } from '@mui/material';
import { Store } from '@mui/icons-material';

import type { StackProps } from '@mui/material';

export type RestaurantTitleProps = {
  imageUri?: string | null;
  name?: string | null;
  branchName?: string | null;
  outerStackProps?: StackProps;
};

const DEFAULT_INIT_FONT_SIZE = 20;
const MIN_FONT_SIZE = 1;

const RestaurantTitle = ({ imageUri, name, branchName, outerStackProps }: RestaurantTitleProps) => {
  const textRef = React.useRef<HTMLSpanElement | null>(null);
  const textContainerRef = React.useRef<HTMLDivElement | null>(null);
  const initFontSizeRef = React.useRef(DEFAULT_INIT_FONT_SIZE);

  const restaurantNameWithBranchName = React.useMemo(() => join([name, branchName], ' '), [name, branchName]);
  const [scaledFontSize, setScaledFontSize] = React.useState<number>();

  React.useLayoutEffect(() => {
    if (textRef.current) {
      const initFontSize = parseFloat(window.getComputedStyle(textRef.current).fontSize);
      initFontSizeRef.current = initFontSize;
    }
  }, []);

  React.useLayoutEffect(() => setScaledFontSize(initFontSizeRef.current), [restaurantNameWithBranchName]);

  React.useLayoutEffect(() => {
    if (!(textRef.current && textContainerRef.current)) {
      return;
    }

    if (textRef.current.clientHeight > textContainerRef.current.clientHeight) {
      setScaledFontSize((fontSize) => {
        const nextFontSize = (fontSize ?? initFontSizeRef.current) - 1;
        return max([nextFontSize, MIN_FONT_SIZE]);
      });
    }
  }, [restaurantNameWithBranchName, scaledFontSize]);

  return (
    <Stack flexDirection="row" alignItems="center" height="100%" gap={1} {...outerStackProps}>
      <Avatar src={imageUri ?? undefined} sx={{ width: 48, height: 48 }}>
        <Store />
      </Avatar>

      <Stack ref={textContainerRef} height="100%" justifyContent="center" overflow="hidden">
        <Typography ref={textRef} variant="h6" fontSize={scaledFontSize}>
          {restaurantNameWithBranchName}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default RestaurantTitle;
