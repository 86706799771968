import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import {
  Alert,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { find, get, isEmpty } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import type { DialogProps } from '@mui/material';
import { LoadingButton } from '../../../components';
import { AppContext } from '../../../contexts/app_context';
import { createScopedI18n } from '../../../i18n/i18n';
import { formatDate, joinPairs } from '../../../utils/libs';

const exportJobHiringsExcelDialogI18n = createScopedI18n('components.export_job_hirings_excel_dialog');
const exportJobHiringsExcelErrorI18n = createScopedI18n('graphql.errors.types.export_job_hirings.fields', {
  joinOutput: true,
});

export type ExportJobHiringsExcelDialogProps = {
  date: Date | string;
  restaurantId: string;
} & DialogProps;

const exportJobHiringsExcelSchema = Yup.object({
  email: Yup.string()
    .required(exportJobHiringsExcelErrorI18n('email.blank'))
    .email(exportJobHiringsExcelErrorI18n('email.invalid')),
});

type exportJobHiringsExcelValueType = Yup.InferType<typeof exportJobHiringsExcelSchema>;

const exportJobHiringsExcelGql = gql(`
  mutation exportJobHiringsExcel($date: Date!, $restaurantId: ID!, $email: String) {
    exportJobHirings(date: $date, restaurantId: $restaurantId, email: $email) {
      success
      errors
    }
  }
`);

export const ExportJobHiringsExcelDialog = ({
  date,
  restaurantId,
  onClose,
  ...props
}: ExportJobHiringsExcelDialogProps) => {
  const { contextUser, contextRestaurants } = React.useContext(AppContext);
  const restaurant = find(contextRestaurants, ({ id }) => id === restaurantId);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { email: contextUser?.email ?? '' },
    resolver: yupResolver(exportJobHiringsExcelSchema),
  });

  const [exportJobHiringsExcelMutation] = useMutation(exportJobHiringsExcelGql);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const submitHandler = async (values: exportJobHiringsExcelValueType) => {
    setErrorMessage(null);

    try {
      const { data } = await exportJobHiringsExcelMutation({
        variables: {
          date: dayjs(date).format('YYYY-MM-DD'),
          restaurantId,
          email: values.email,
        },
      });

      if (data?.exportJobHirings?.success) {
        onClose && onClose({}, 'backdropClick');
        enqueueSnackbar({
          variant: 'success',
          message: exportJobHiringsExcelDialogI18n('success_sent_email', { email: values.email }),
        });
      } else {
        const errors = data?.exportJobHirings.errors;
        setErrorMessage(joinPairs(errors));
      }
    } catch (error) {
      setErrorMessage(get(error, 'message', null));
    }
  };

  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle display="inline-flex" alignItems="center" gap={1}>
        <Typography flex={1} variant="inherit">
          {exportJobHiringsExcelDialogI18n('send_report')}
        </Typography>
        <IconButton onClick={(event) => onClose && onClose(event, 'backdropClick')}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography>{exportJobHiringsExcelDialogI18n('report_confirm')}</Typography>
        <Typography>
          {exportJobHiringsExcelDialogI18n('report_branch_name', {
            branch_name: ` ${restaurant?.name || ''} ${restaurant?.branchName || ''}`,
          })}
        </Typography>
        <Typography>{exportJobHiringsExcelDialogI18n('report_date', { date: formatDate(date) })}</Typography>

        <Controller
          key="email"
          name="email"
          control={control}
          render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
            <TextField
              id={field.name}
              margin="dense"
              label={exportJobHiringsExcelDialogI18n('send_to_email')}
              type="email"
              value={value ?? ''}
              error={invalid}
              helperText={error?.message ?? ' '}
              {...field}
            />
          )}
        />
        <Typography color="text.secondary">{exportJobHiringsExcelDialogI18n('disclaimer_temp_name')}</Typography>
      </DialogContent>

      <Collapse in={!isEmpty(errorMessage)} sx={{ width: '100%' }}>
        <Alert severity="error" sx={{ margin: 1 }}>
          {errorMessage}
        </Alert>
      </Collapse>

      <DialogActions>
        <LoadingButton
          variant="contained"
          fullWidth
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={handleSubmit(submitHandler)}
        >
          {exportJobHiringsExcelDialogI18n('send_report')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
