import React from 'react';

import { Alert, Box, Button, Stack } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import type { BoxProps, AlertProps } from '@mui/material';
import ContainerFlexHeight from './container_flex_height';
import { createScopedI18n, i18n } from '../i18n/i18n';

import type { ContainerFlexHeightProps } from './container_flex_height';

const errorPageI18n = createScopedI18n('pages.error');

export type ErrorMessageBoxProps = {
  errorMessage?: string | null;
  hideRefreshButton?: boolean;
  goToButton?: React.ReactNode;
  AlertProps?: AlertProps;
  BoxProps?: BoxProps;
  ContainerProps?: ContainerFlexHeightProps;
};

const ErrorMessageBox = ({
  errorMessage,
  hideRefreshButton,
  goToButton,
  AlertProps,
  BoxProps,
  ContainerProps,
}: ErrorMessageBoxProps) => {
  const reloadWebHandler = React.useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Box display="flex" height="100vh" flexDirection="column" {...BoxProps}>
      <ContainerFlexHeight
        maxWidth="xs"
        {...ContainerProps}
        sx={[
          { display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' },
          ...(Array.isArray(ContainerProps?.sx) ? ContainerProps?.sx ?? [] : [ContainerProps?.sx]),
        ]}
      >
        <Stack justifyContent="center" width="100%" margin={2} gap={2}>
          <Alert severity="error" {...AlertProps}>
            {errorMessage ?? i18n.t('general.error')}
          </Alert>

          <Stack flexDirection="row" gap={1}>
            {!hideRefreshButton && (
              <Button fullWidth variant="outlined" onClick={reloadWebHandler} startIcon={<Refresh />}>
                {errorPageI18n('refresh_button')}
              </Button>
            )}

            {goToButton}
          </Stack>
        </Stack>
      </ContainerFlexHeight>
    </Box>
  );
};

export default ErrorMessageBox;
