import React, { useEffect, useState } from 'react';
import { Button, TextField, Box, Typography, Divider } from '@mui/material';
import { isNumber } from 'lodash';
import { createScopedI18n } from '../../../i18n/i18n';

interface TipAmountSelectorProps {
  onTipChange: (tip: number) => void;
  totalStudents: number;
}

const tipAmountSelectorI18n = createScopedI18n('components.tip_amount_selector');

const roundedValue = (value: number) => Math.floor(value * 100) / 100;

export const TipAmountSelector: React.FC<TipAmountSelectorProps> = ({ onTipChange, totalStudents }) => {
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalStudentsSelected, setTotalStudentsSelected] = useState<number>(0);

  const [isShowTextInput, setIsShowTextInput] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<string | null>(null);

  useEffect(() => {
    if (isNumber(totalStudents)) {
      setTotalStudentsSelected(totalStudents);
    }
  }, [totalStudents]);

  useEffect(() => {
    if (totalStudentsSelected > 0) {
      onTipChange(roundedValue(totalAmount / totalStudentsSelected));
    }
  }, [totalAmount, totalStudentsSelected, onTipChange]);

  const presetAmountHandler = (value: string) => {
    if (totalAmount <= 0 || Number(value) != totalAmount) {
      const numericValue = Number(value);
      setTotalAmount(numericValue);
      setActiveButton(value);
      setIsShowTextInput(false);
    } else {
      setActiveButton(null);
      setTotalAmount(0);
    }
  };

  const customAmountHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numericValue = Number(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      setTotalAmount(numericValue);
    }
  };

  const otherAmountHandler = () => {
    setIsShowTextInput(!isShowTextInput);
    setActiveButton(null);
    setTotalAmount(0);
  };

  const presetAmounts = ['50', '100', '200'];

  return (
    <>
      <Typography alignSelf="center" variant="body2">
        {tipAmountSelectorI18n('title')}
      </Typography>
      <Box sx={{ maxWidth: 400, mx: 'auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, gap: 1 }}>
          {presetAmounts.map((value) => (
            <Button
              key={value}
              size="small"
              variant="contained"
              onClick={() => presetAmountHandler(value)}
              sx={{
                'bgcolor': activeButton === value ? 'primary.main' : 'grey.300',
                'color': activeButton === value ? 'white' : 'black',
                '&:hover': { bgcolor: activeButton === value ? 'primary.dark' : 'grey.400' },
                'minWidth': '50px',
                'px': 1,
                'flex': 1,
              }}
            >
              {value}
            </Button>
          ))}
          <Button
            size="small"
            variant="contained"
            onClick={otherAmountHandler}
            sx={{
              'bgcolor': isShowTextInput ? 'primary.main' : 'grey.300',
              'color': isShowTextInput ? 'white' : 'black',
              '&:hover': { bgcolor: isShowTextInput ? 'primary.dark' : 'grey.400' },
              'minWidth': '60px',
              'px': 1,
              'flex': 1,
            }}
          >
            {tipAmountSelectorI18n('custom')}
          </Button>
        </Box>
        {isShowTextInput && (
          <>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {tipAmountSelectorI18n('custom_description')}
            </Typography>
            <TextField
              fullWidth
              autoFocus={true}
              variant="outlined"
              value={totalAmount || ''}
              onChange={customAmountHandler}
              size="small"
              inputProps={{ style: { textAlign: 'right' } }}
            />
          </>
        )}
      </Box>
      <Box sx={{ mb: 1 }}>
        <Typography variant="body2" sx={{ mb: 0.5 }}>
          {tipAmountSelectorI18n('credit_per_temp', {
            amount: totalStudents > 0 ? roundedValue(totalAmount / totalStudentsSelected) : 0,
          })}{' '}
          {totalStudentsSelected > 0 &&
            tipAmountSelectorI18n('selected_people', {
              count: totalStudentsSelected,
            })}
        </Typography>
        <Divider />
      </Box>
    </>
  );
};
