import React from 'react';
import { chain, isArray, isString, map, flatten, isNil, join, snakeCase, first, isEmpty } from 'lodash';
import { I18n } from 'i18n-js';
import thDefault from 'i18n-js/json/th.json';
import reactStringReplace from 'react-string-replace';

import type { Scope, TranslateOptions } from 'i18n-js';
import { ApolloError } from '@apollo/client';
import th from './th.json';

const i18n = new I18n(
  { ...thDefault },
  {
    defaultLocale: 'th',
    locale: 'th',
    enableFallback: true,
    missingBehavior: import.meta.env.PROD ? 'guess' : 'message',
  },
);

type TranslateOptionsExtent = { joinOutput?: string | boolean } & TranslateOptions;
// Base on [this Github issue comment](https://github.com/fnando/i18n/issues/56#issuecomment-1625551095)
// @ts-expect-error
i18n.interpolate = (i18n: I18n, message: string, options: TranslateOptionsExtent) => {
  options = Object.keys(options).reduce((buffer, key) => {
    buffer[i18n.transformKey(key)] = options[key];
    return buffer;
  }, {} as TranslateOptions);

  const replacedMessage = reactStringReplace(message, i18n.placeholder, (match, i) => {
    let value: string | React.ReactElement;
    const placeholder = match as string;
    const name = placeholder.replace(i18n.placeholder, '$1');

    if (!isNil(options[name])) {
      if (React.isValidElement(options[name])) {
        value = React.createElement(React.Fragment, { key: i, children: options[name] });
        if (options.joinOutput)
          console.error(`value "${name}" is a React.ReactNode and might show up as "[object Object]"`);
      } else {
        value = options[name].toString().replace(/\$/gm, '_#$#_');
      }
    } else if (name in options) {
      value = i18n.nullPlaceholder(i18n, placeholder, message, options);
    } else {
      value = i18n.missingPlaceholder(i18n, placeholder, message, options);
    }

    return value;
  });

  if (options.joinOutput) {
    return join(replacedMessage, options.joinOutput === true ? '' : options.joinOutput);
  }
  return replacedMessage;
};

i18n.store(th);

const joinScope = (...scopes: Scope[]) => flatten(scopes) as Scope;

const createI18nPrefixScope = (prefixScope?: Scope) => (scope: Scope) => joinScope(prefixScope ?? [], scope);

const createScopedI18n = (prefixScope?: Scope, defaultOptions?: TranslateOptionsExtent | undefined) => {
  const defaultScope = createI18nPrefixScope(prefixScope);
  return (scope: Scope, options?: TranslateOptionsExtent | undefined) =>
    i18n.t(defaultScope(scope), { ...defaultOptions, ...options });
};

const createI18nErrorMessages = (response: ApolloError): string => {
  const queryOrMutationName = first(Object.keys(response)) || '';
  const fieldName = `graphql.fields.${snakeCase(queryOrMutationName)}`;
  const errorMessage = `graphql.errors.types.${snakeCase(queryOrMutationName)}.fields`;
  const { errors } = (response as any)[queryOrMutationName]; // eslint-disable-line @typescript-eslint/no-explicit-any

  const translateMain = (key: string) => {
    const message = i18n.t([fieldName, key]);
    return isString(message) && message.startsWith('[missing') ? key : message;
  };

  return chain(errors)
    .map((errorGroup) => {
      if (!isArray(errorGroup)) return errorGroup;

      const [mainError, ...restErrors] = errorGroup;

      const translate = (key: string) => {
        const message = i18n.t([errorMessage, mainError, key]);
        return isString(message) && message.startsWith('[missing') ? key : message;
      };

      const mainErrorOutput = translateMain(mainError);
      const additionalMessages = map(restErrors, translate).join(' ');

      return `${mainErrorOutput} ${additionalMessages}`.trim();
    })
    .join(', ')
    .value();
};
export { createI18nPrefixScope, createScopedI18n, createI18nErrorMessages, i18n, joinScope };
