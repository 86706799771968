import { Chip } from '@mui/material';

import type { ChipProps } from '@mui/material';

export type StudentPropertyChipProps = {
  type?: 'certificate' | 'experience' | 'isWorkedHere' | 'preExperience' | 'skill' | string;
} & ChipProps;

const StudentPropertyChip = ({ type, ...props }: StudentPropertyChipProps) => {
  let chipColor: 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' = 'primary';
  switch (type) {
    case 'certificate':
      chipColor = 'warning';
      break;
    case 'experience':
      chipColor = 'success';
      break;
    case 'isWorkedHere':
      chipColor = 'info';
      break;
    case 'preExperience':
      chipColor = 'error';
      break;
    case 'skill':
      chipColor = 'secondary';
      break;

    default:
      chipColor = 'primary';
      break;
  }

  return <Chip color={chipColor} {...props} />;
};

export default StudentPropertyChip;
