import React from 'react';

import { Home } from '@mui/icons-material';
import { Button } from '@mui/material';
import { get, isEmpty } from 'lodash';
import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { ErrorMessageBox } from '../components';
import { createScopedI18n } from '../i18n/i18n';

const errorPageI18n = createScopedI18n('pages.error');

const RootError = () => {
  const routeError = useRouteError();

  const errorMessage = React.useMemo(() => {
    const error = isRouteErrorResponse(routeError) ? routeError.error : routeError;

    // eslint-disable-next-line @typescript-eslint/no-shadow
    let errorMessage: string | undefined = typeof error === 'string' ? error : get(error, 'message');

    const status: number | undefined = get(routeError, 'status');
    const statusText: string | undefined = get(routeError, 'statusText');

    if (status === 404) {
      errorMessage = errorPageI18n('error_404');
    } else if (status === 503 || get(error, 'message') === 'Failed to fetch') {
      errorMessage = errorPageI18n('error_503');
    }

    if (isEmpty(errorMessage) && status && statusText) {
      errorMessage = `${status} ${statusText}`;
    }

    return errorMessage;
  }, [routeError]);

  return (
    <ErrorMessageBox
      errorMessage={errorMessage}
      goToButton={
        <Button fullWidth variant="outlined" component={Link} to="/" replace startIcon={<Home />}>
          {errorPageI18n('go_back_home_button')}
        </Button>
      }
    />
  );
};

export default RootError;
