import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Home } from '@mui/icons-material';
import {
  Alert,
  Button,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { compact, get, isNil, map, some } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  BottomSafeArea,
  ErrorMessageBox,
  LoadingButton,
  LoadingSpinner,
  MapsPickerCard,
  UploadImagePickerCard,
  useGoBack,
  useHttpRequest,
  useTrackPageView,
} from '../../components';
import Protector from '../../components/protector';
import { AppContext } from '../../contexts/app_context';
import { createScopedI18n, i18n } from '../../i18n/i18n';
import { useLoadRestaurantsContext } from '../../routes/use_load_restaurants_context';
import { joinPairs, labelForEnum } from '../../utils/libs';

const restaurantCreatePageI18n = createScopedI18n('pages.restaurants_new');
const restaurantFieldI18n = createScopedI18n('graphql.fields.restaurant');
const restaurantErrorI18n = createScopedI18n('graphql.errors.types.restaurant.fields', { joinOutput: true });

const createRestaurantGql = gql(`
  mutation createRestaurant(
    $branchName: String
    $businessType: String!
    $landmark: String
    $latitude: Float!
    $longitude: Float!
    $googleMapsPlaceId: String
    $name: String!
    $photoTokens: [RestaurantPhotoTokenInput!]
  ) {
    createRestaurant(
      branchName: $branchName
      businessType: $businessType
      landmark: $landmark
      latitude: $latitude
      longitude: $longitude
      googleMapsPlaceId: $googleMapsPlaceId
      name: $name
      photoTokens: $photoTokens
    ) {
      success
      errors
      data {
        id
        branchName
        businessType
        landmark
        latitude
        longitude
        googleMapsPlaceId
        name
        frontPhotoUri
        isInfoCompletedV2
      }
    }
  }
`);

const createRestaurantSchema = Yup.object({
  name: Yup.string().required(restaurantErrorI18n('name.blank')),
  branchName: Yup.string(),
  businessType: Yup.string().required(restaurantErrorI18n('business_type.blank')),
  landmark: Yup.string().required(restaurantErrorI18n('landmark.blank')),
  location: Yup.object({
    latitude: Yup.number().required(restaurantErrorI18n('location.blank')),
    longitude: Yup.number().required(restaurantErrorI18n('location.blank')),
  }).required(restaurantErrorI18n('location.blank')),
  placeId: Yup.string(),
  photoFrontToken: Yup.string().required(restaurantErrorI18n('photo.blank')),
  photoAdd1Token: Yup.string().required(restaurantErrorI18n('photo.blank')),
  photoAdd2Token: Yup.string().required(restaurantErrorI18n('photo.blank')),
});

type CreateRestaurantValueType = Yup.InferType<typeof createRestaurantSchema>;

// eslint-disable-next-line import/prefer-default-export
export const RestaurantsNewPage = () => {
  const navigate = useNavigate();

  const isFirstNewRestaurant = useMatch('/first_new_restaurant');
  const goBack = useGoBack({ fallbackPath: isFirstNewRestaurant ? '/' : '/restaurants' });

  useTrackPageView('RestaurantNewPage');

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors: validateErrors },
  } = useForm({
    resolver: yupResolver(createRestaurantSchema),
  });

  const { contextRedirectFrom, setContextRedirectFrom, setContextCurrentRestaurant } = React.useContext(AppContext);
  const { fetchRestaurantsAndPermissions } = useLoadRestaurantsContext({ skip: true });

  const { isLoading: isSelectableBusinessTypesLoading, response } = useHttpRequest({
    method: 'get',
    url: 'enums/business_types',
  });
  const selectableBusinessTypes: string[] = response?.data ?? [];

  const photosInvalid = some(
    [validateErrors.photoFrontToken, validateErrors.photoAdd1Token, validateErrors.photoAdd2Token],
    (error) => !isNil(error),
  );

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [createRestaurantMutation] = useMutation(createRestaurantGql);

  const submitHandler = async (value: CreateRestaurantValueType) => {
    const photoTokens = compact([
      value.photoFrontToken ? { token: value.photoFrontToken, photoType: 'front' } : undefined,
      value.photoAdd1Token ? { token: value.photoAdd1Token, photoType: 'additional1' } : undefined,
      value.photoAdd2Token ? { token: value.photoAdd2Token, photoType: 'additional2' } : undefined,
    ]);

    try {
      const { data } = await createRestaurantMutation({
        variables: {
          name: value.name,
          branchName: value.branchName,
          businessType: value.businessType,
          landmark: value.landmark,
          latitude: value.location.latitude,
          longitude: value.location.longitude,
          googleMapsPlaceId: value.placeId,
          photoTokens,
        },
      });

      if (data?.createRestaurant.success) {
        const restaurantId = data.createRestaurant.data?.id;
        await fetchRestaurantsAndPermissions(true);

        if (data.createRestaurant.data?.isInfoCompletedV2) {
          if (contextRedirectFrom) {
            navigate(contextRedirectFrom, { replace: true });
            setContextRedirectFrom(null);
          } else if (restaurantId) {
            setContextCurrentRestaurant(data.createRestaurant.data);
            // const newPermission = await getUserPermissions(contextUser?.id, restaurantId);
            // if (newPermission) {
            //   await setContextPermissions(newPermission || null);
            // }
            navigate(`/restaurants/${restaurantId}`, { replace: true });
          } else {
            goBack();
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (restaurantId) {
            navigate(`/restaurants/${restaurantId}/edit`, { replace: true });
          }
        }
      } else {
        const errors = data?.createRestaurant.errors;
        setErrorMessage(joinPairs(errors));
      }
    } catch (error) {
      setErrorMessage(get(error, 'message', null));
    }
  };

  return (
    <Protector permissions={['restaurantCreate']}>
      <Protector.Allowed>
        <Container maxWidth="sm" sx={{ paddingY: 2 }}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Grid container gap={2}>
              <Grid item container gap={1}>
                <FormControl fullWidth error={photosInvalid}>
                  <Stack gap={0.5}>
                    <FormLabel>{restaurantFieldI18n('photo')} *</FormLabel>
                    <Controller
                      key="photoFrontToken"
                      control={control}
                      name="photoFrontToken"
                      render={({ field: { onChange, ...field } }) => (
                        <UploadImagePickerCard
                          id={field.name}
                          buttonText={restaurantCreatePageI18n('select_photo', {
                            photo_name: restaurantFieldI18n('photos.photo_front'),
                          })}
                          onUploadDone={(token) => onChange(token)}
                          sx={{ aspectRatio: 2 }}
                        />
                      )}
                    />
                    <Stack flexDirection="row" gap={0.5}>
                      <Controller
                        key="photoAdd1Token"
                        control={control}
                        name="photoAdd1Token"
                        render={({ field: { onChange, ...field } }) => (
                          <UploadImagePickerCard
                            id={field.name}
                            buttonText={restaurantCreatePageI18n('select_photo', {
                              photo_name: restaurantFieldI18n('photos.photo_add_1'),
                            })}
                            onUploadDone={(token) => onChange(token)}
                            sx={{ aspectRatio: 3 / 2 }}
                          />
                        )}
                      />
                      <Controller
                        key="photoAdd2Token"
                        control={control}
                        name="photoAdd2Token"
                        render={({ field: { onChange, ...field } }) => (
                          <UploadImagePickerCard
                            id={field.name}
                            buttonText={restaurantCreatePageI18n('select_photo', {
                              photo_name: restaurantFieldI18n('photos.photo_add_2'),
                            })}
                            onUploadDone={(token) => onChange(token)}
                            sx={{ aspectRatio: 3 / 2 }}
                          />
                        )}
                      />
                    </Stack>
                    <Collapse in={photosInvalid}>
                      <Alert severity="error">
                        {validateErrors.photoFrontToken && (
                          <Typography variant="body2">
                            • {restaurantFieldI18n('photos.photo_front')} {validateErrors.photoFrontToken.message}
                          </Typography>
                        )}
                        {validateErrors.photoAdd1Token && (
                          <Typography variant="body2">
                            • {restaurantFieldI18n('photos.photo_add_1')} {validateErrors.photoAdd1Token.message}
                          </Typography>
                        )}
                        {validateErrors.photoAdd2Token && (
                          <Typography variant="body2">
                            • {restaurantFieldI18n('photos.photo_add_2')} {validateErrors.photoAdd2Token.message}
                          </Typography>
                        )}
                      </Alert>
                    </Collapse>
                  </Stack>
                </FormControl>
              </Grid>

              <Controller
                key="name"
                control={control}
                name="name"
                render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                  <FormControl id={field.name} fullWidth error={invalid}>
                    <Grid item container alignItems="center" gap={1}>
                      <Grid item xs={3}>
                        <FormLabel>{restaurantFieldI18n('name')} *</FormLabel>
                      </Grid>
                      <Grid item xs>
                        <TextField
                          id={field.name}
                          type="text"
                          fullWidth
                          value={value ?? ''}
                          error={invalid}
                          helperText={error?.message}
                          {...field}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                )}
              />

              <Controller
                key="branchName"
                control={control}
                name="branchName"
                render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                  <FormControl id={field.name} fullWidth error={invalid}>
                    <Grid item container alignItems="center" gap={1}>
                      <Grid item xs={3}>
                        <FormLabel>
                          {restaurantFieldI18n('branch_name')}
                          <br />
                          {restaurantCreatePageI18n('branch_name_hint')}
                        </FormLabel>
                      </Grid>
                      <Grid item xs>
                        <TextField
                          id={field.name}
                          type="text"
                          fullWidth
                          value={value ?? ''}
                          error={invalid}
                          helperText={error?.message}
                          {...field}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                )}
              />

              <Controller
                key="businessType"
                control={control}
                name="businessType"
                render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                  <FormControl id={field.name} fullWidth error={invalid}>
                    <FormLabel>
                      {restaurantFieldI18n('business_type')} *{' '}
                      <Link to="#">{restaurantCreatePageI18n('business_type_hint')}</Link>
                    </FormLabel>
                    <Collapse in={isSelectableBusinessTypesLoading} unmountOnExit>
                      <LoadingSpinner size={24} sx={{ marginTop: 2 }} />
                    </Collapse>
                    <Collapse in={!isSelectableBusinessTypesLoading}>
                      <RadioGroup value={value ?? null} {...field} sx={{ paddingLeft: 2, paddingTop: 2, gap: 1 }}>
                        {map(selectableBusinessTypes, (selectableBusinessType) => (
                          <FormControlLabel
                            key={selectableBusinessType}
                            value={selectableBusinessType}
                            label={labelForEnum('business_type', selectableBusinessType)}
                            control={<Radio />}
                          />
                        ))}
                      </RadioGroup>
                    </Collapse>
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />

              <Controller
                key="landmark"
                control={control}
                name="landmark"
                render={({ field: { value, ...field }, fieldState: { invalid, error } }) => (
                  <FormControl id={field.name} fullWidth error={invalid}>
                    <FormLabel>{restaurantFieldI18n('landmark')} *</FormLabel>
                    <TextField
                      id={field.name}
                      type="text"
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={8}
                      value={value ?? ''}
                      error={invalid}
                      helperText={error?.message}
                      {...field}
                    />
                  </FormControl>
                )}
              />

              <Controller
                key="location"
                control={control}
                name="location"
                render={({ field: { value, onChange, ...field }, fieldState: { invalid, error } }) => (
                  <FormControl id={field.name} fullWidth error={invalid}>
                    <FormLabel>{restaurantFieldI18n('location')} *</FormLabel>
                    <MapsPickerCard
                      location={value}
                      onConfirm={(_e, result) => {
                        if (result.location) {
                          onChange(result.location);
                        }
                        setValue('placeId', result.placeId ?? undefined);
                      }}
                      sx={{ aspectRatio: 2.5 }}
                    />
                    <FormHelperText variant="outlined">
                      {get(error, ['latitude', 'message']) ?? get(error, ['longitude', 'message'])}
                    </FormHelperText>
                  </FormControl>
                )}
              />

              <Collapse in={!!errorMessage} sx={{ width: '100%' }}>
                <Alert severity="error">{errorMessage}</Alert>
              </Collapse>
              <LoadingButton type="submit" disabled={isSubmitting} loading={isSubmitting} fullWidth variant="contained">
                {i18n.t('general.register')}
              </LoadingButton>
            </Grid>
          </form>

          <BottomSafeArea />
        </Container>
      </Protector.Allowed>
      <Protector.Rejected>
        <ErrorMessageBox
          hideRefreshButton
          errorMessage={restaurantCreatePageI18n('no_restaurant_create_permission')}
          goToButton={
            <Button fullWidth variant="outlined" component={Link} to="/" replace startIcon={<Home />}>
              {i18n.t('pages.error.go_back_home_button')}
            </Button>
          }
        />
      </Protector.Rejected>
    </Protector>
  );
};
