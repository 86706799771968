const RUN_ENV = import.meta.env.RUN_ENV;
const APP_NAME = import.meta.env.VITE_APP_NAME;
const APP_VERSION = import.meta.env.VITE_APP_VERSION;
const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
const FCM_VAPID_KEY = import.meta.env.VITE_FCM_VAPID_KEY;

const GB_PRIME_PAY_CARD_TOKEN_URL = import.meta.env.VITE_GB_PRIME_PAY_CARD_TOKEN_URL;
const GB_PRIME_PAY_PUBLIC_KEY = import.meta.env.VITE_GB_PRIME_PAY_PUBLIC_KEY;

const GOLDEN_RATIO = 1.618033988749894;

const HOLIDAY_LIST: Record<string, boolean> = {
  '2023-12-29': true,
  '2023-12-31': true,
  '2024-01-01': true,
  '2024-02-24': true,
  '2024-04-06': true,
  '2024-04-13': true,
  '2024-04-14': true,
  '2024-04-15': true,
  '2024-05-01': true,
  '2024-05-04': true,
  '2024-05-22': true,
  '2024-06-03': true,
  '2024-07-20': true,
  '2024-07-21': true,
  '2024-07-28': true,
  '2024-08-12': true,
  '2024-10-13': true,
  '2024-10-23': true,
  '2024-12-05': true,
  '2024-12-10': true,
  '2024-12-31': true,
};
const SPECIAL_HOLIDAY_LIST: Record<string, boolean> = {
  '2025-01-01': true,
  '2025-04-13': true,
  '2025-04-14': true,
  '2025-04-15': true,
};

export {
  APP_NAME,
  APP_VERSION,
  GOLDEN_RATIO,
  GB_PRIME_PAY_CARD_TOKEN_URL,
  GB_PRIME_PAY_PUBLIC_KEY,
  RUN_ENV,
  GOOGLE_MAPS_API_KEY,
  FCM_VAPID_KEY,
  HOLIDAY_LIST,
  SPECIAL_HOLIDAY_LIST,
};
