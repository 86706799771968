import React from 'react';

import { Stack, useTheme } from '@mui/material';
import { groupBy, map, orderBy } from 'lodash';
import type { StackProps } from '@mui/material';
import dayjs from 'dayjs';
import DateJobHiringGroup from './date_job_hirings_group';
import { RestaurantTitle } from '../../../components';

import type { RestaurantType } from '../../../contexts/app_context';

export type StudentType = {
  id: string;
  avatar?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  nickName?: string | null;
};

export type JobHiringType = {
  id: string;
  status: string;
  startOperationTime?: string | null;
  endOperationTime?: string | null;
  breakDurationMinute?: number | null;
  student: StudentType;
  studentReview?: { id: string } | null;
};

export type JobType = {
  id: string;
  status: string;
  jobType: string;
  scheduleDate: string;
  jobStartAt: string;
  jobEndAt: string;
  jobHirings: JobHiringType[];
};

export type RestaurantDateJobHiringsListProps = {
  jobs: JobType[];
  restaurant: RestaurantType;
  outerStackProps?: StackProps;
};

const RestaurantDateJobHiringsList = React.forwardRef<HTMLDivElement, RestaurantDateJobHiringsListProps>(
  ({ jobs, restaurant, outerStackProps }, ref) => {
    const theme = useTheme();

    const dateGroupJobs = React.useMemo(
      () =>
        groupBy(
          orderBy(jobs, ({ jobStartAt }) => dayjs(jobStartAt).toDate(), 'asc'),
          (job) => job.scheduleDate,
        ),
      [jobs],
    );

    return (
      <Stack
        ref={ref}
        flexShrink={0}
        border={1}
        borderRadius={1}
        borderColor={theme.palette.divider}
        {...outerStackProps}
      >
        <Stack paddingX={1} paddingTop={0.75}>
          <Stack flexDirection="row" alignItems="center" height={64}>
            <RestaurantTitle
              imageUri={restaurant.frontPhotoUri}
              name={restaurant.name}
              branchName={restaurant.branchName}
            />
          </Stack>
        </Stack>

        <Stack flex={1} sx={{ overflowY: 'scroll', scrollPadding: '16px' }}>
          <Stack gap={1} padding={0.75}>
            {map(dateGroupJobs, (groupedJobs, date) => (
              <DateJobHiringGroup key={date} date={date} jobs={groupedJobs} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    );
  },
);

export default RestaurantDateJobHiringsList;
