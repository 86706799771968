import React from 'react';

import { gql, useMutation } from '@apollo/client';
import { Alert, Button, Collapse, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { get, isEmpty, noop } from 'lodash';
import type { DialogProps } from '@mui/material';
import { LoadingButton } from '../../../components';
import { createScopedI18n, createI18nErrorMessages } from '../../../i18n/i18n';

const settleJobGql = gql(`
  mutation settleJob($jobId: ID!) {
    settleJob(jobId: $jobId) {
      success
      errors
    }
  }
`);

const jobDetailPageI18n = createScopedI18n('pages.jobs_id');

export type CloseJobDialogProps = {
  jobId?: string;
  onSuccess?: () => void;
} & DialogProps;

export const CloseJobDialog = ({ jobId, onClose, onSuccess, ...dialogProps }: CloseJobDialogProps) => {
  const [closeJobMutation, { loading }] = useMutation(settleJobGql);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const submitCloseJobHandler = async () => {
    if (isEmpty(jobId)) return;

    setErrorMessage(null);

    try {
      const { data } = await closeJobMutation({ variables: { jobId } });

      if (data?.settleJob?.success) {
        onClose && onClose({}, 'backdropClick');
        onSuccess && onSuccess();
      } else {
        const jobDetailPageI18nErrorI18n = createI18nErrorMessages(data);
        setErrorMessage(jobDetailPageI18nErrorI18n);
      }
    } catch (error) {
      setErrorMessage(get(error, 'message', null));
    }
  };

  return (
    <Dialog onClose={onClose} {...dialogProps}>
      <DialogTitle>{jobDetailPageI18n('close_job_title')}</DialogTitle>

      <Collapse in={!isEmpty(errorMessage)} sx={{ width: '100%', paddingX: 2 }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Collapse>

      <DialogActions>
        <Button variant="outlined" disableElevation onClick={onClose ?? noop}>
          {jobDetailPageI18n('continue_job')}
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          disabled={loading}
          onClick={submitCloseJobHandler}
        >
          {jobDetailPageI18n('close_job')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
