import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Add, Remove, Close } from '@mui/icons-material';
import { useMutation, useLazyQuery, gql } from '@apollo/client';
import { isEmpty, reduce } from 'lodash';
import numeral from 'numeral';
import { AppContext } from '../contexts/app_context';
import { dateClassifier } from '../utils/libs';
import { createScopedI18n, createI18nErrorMessages } from '../i18n/i18n';
import LoadingSpinner from './loading_spinner';
import { NumberTextField } from './number_text_field';

const adjustWageDialogI18n = createScopedI18n('components.adjust_wage_dialog');

const updateBidMutation = gql`
  mutation UpdateBid($jobId: ID!, $restaurantBidPricePerHour: Float!) {
    updateJobRestaurantBidPrice(jobId: $jobId, restaurantBidPricePerHour: $restaurantBidPricePerHour) {
      success
      errors
    }
  }
`;

const getJobBidPrice = gql`
  query GetJobBidPrice($jobId: ID!) {
    job(id: $jobId) {
      id
      restaurantBidPricePerHour
      scheduleDate
      breakDurationMinute
      jobDurationMinute
      jobUrgent
    }
  }
`;

const getPriceRange = gql`
  query getPriceRange($businessType: BUSINESS_TYPE_OPTIONS!) {
    jobCreatingOption(businessType: $businessType) {
      otHour
      pricing {
        id
        price
        priceType
      }
      pricingRange {
        id
        min
        max
        priceType
      }
    }
  }
`;

const INITIAL_WAGE = 60;
const WAGE_INCREMENT = 1;

interface AdjustWageDialogProps {
  open: boolean;
  onClose: () => void;
  jobId: string;
  onSuccess?: () => void;
}

const AdjustWageDialog: React.FC<AdjustWageDialogProps> = ({ open, onClose, jobId, onSuccess }) => {
  const { contextCurrentRestaurant } = React.useContext(AppContext);

  const [wage, setWage] = useState<number>(INITIAL_WAGE);
  const [workingHour, setWorkingHour] = useState<number>(0);
  const [totalWage, setTotalWage] = useState<number>(0);
  const [initialWage, setInitialWage] = useState<number>(INITIAL_WAGE);
  const [newMinPrice, setNewMinPrice] = useState<number>(INITIAL_WAGE);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [fetchJobBidPrice, { data: bidPriceData, loading: loadingBidPrice, error: bidPriceError }] = useLazyQuery(
    getJobBidPrice,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [fetchPriceRange, { data: jobCreateOption, loading: loadingPriceRange, error: priceRangeError }] =
    useLazyQuery(getPriceRange);

  useEffect(() => {
    if (open && jobId) {
      fetchJobBidPrice({ variables: { jobId } });

      if (contextCurrentRestaurant?.businessType) {
        fetchPriceRange({
          variables: {
            businessType: contextCurrentRestaurant.businessType,
          },
        });
      }
    }
  }, [open, jobId, contextCurrentRestaurant?.businessType]);

  useEffect(() => {
    if (jobCreateOption) {
      const { pricingRange = [], pricing = [] } = jobCreateOption.jobCreatingOption || {};

      const pricingMap = reduce(
        pricingRange,
        (acc, { priceType, min, max }) => ({
          ...acc,
          [priceType]: { min, max },
        }),
        {} as Record<string, { min: number; max: number }>,
      );

      const urgentPricingMap = reduce(
        pricing,
        (acc, { priceType, price }) => ({
          ...acc,
          [priceType]: price,
        }),
        {} as Record<string, number>,
      );

      const scheduleDate = bidPriceData?.job?.scheduleDate;
      const scheduleDatesToDayType = scheduleDate ? dateClassifier(new Date(scheduleDate)) : 'weekday';
      const isUrgentJob = bidPriceData?.job?.jobUrgent !== 'normal';

      const priceInfo = isUrgentJob
        ? urgentPricingMap[`restaurant_hiring_same_day_${scheduleDatesToDayType}`] ?? 0
        : pricingMap[`restaurant_hiring_normal_${scheduleDatesToDayType}`]?.max ?? 0;

      if (priceInfo) {
        setNewMinPrice(priceInfo);
      }
    }
  }, [jobCreateOption, bidPriceData]);

  useEffect(() => {
    if (bidPriceData?.job) {
      const currentBidPrice = bidPriceData.job.restaurantBidPricePerHour;

      if (currentBidPrice) {
        setWage(currentBidPrice);
        setInitialWage(currentBidPrice);
      } else if (jobCreateOption) {
        setWage(newMinPrice);
        setInitialWage(newMinPrice);
      }
    }
  }, [bidPriceData, jobCreateOption, newMinPrice]);

  useEffect(() => {
    if (!isEmpty(bidPriceData) && !isEmpty(jobCreateOption)) {
      const { jobDurationMinute, breakDurationMinute } = bidPriceData?.job;
      const otHour = jobCreateOption.jobCreatingOption?.otHour;

      const workingHour = jobDurationMinute / 60;

      const regularHours = Math.min(workingHour, otHour); // Up to 8 hours
      const overtimeHours = Math.max(0, workingHour - otHour); // Any hours over 8

      const totalWage = regularHours * wage + overtimeHours * wage * 1.5;

      setWorkingHour(workingHour);
      setTotalWage(totalWage);
    }
  }, [wage, bidPriceData, jobCreateOption]);

  const [updateBid] = useMutation(updateBidMutation);

  const incrementHandler = useCallback(() => setWage((prev) => prev + WAGE_INCREMENT), []);
  const decrementHandler = useCallback(
    () => setWage((prev) => Math.max(prev - WAGE_INCREMENT, initialWage)),
    [initialWage],
  );

  const customAmountBidPriceHandler = (newValue: number | null) => {
    if (newValue !== null && newValue >= 0) {
      setWage(newValue);
    }
  };

  const submitHandler = async () => {
    try {
      const { data } = await updateBid({
        variables: {
          jobId,
          restaurantBidPricePerHour: wage,
        },
      });

      if (data.updateJobRestaurantBidPrice.success) {
        onSuccess?.();
        onClose();
      } else {
        const jobBidPriceI18nErrorI18n = createI18nErrorMessages(data);
        setErrorMessage(jobBidPriceI18nErrorI18n);
      }
    } catch {}
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {adjustWageDialogI18n('title')}
        <IconButton onClick={onClose} aria-label="Close dialog">
          <Close />
        </IconButton>
      </DialogTitle>

      {loadingBidPrice || loadingPriceRange ? (
        <LoadingSpinner size={24} sx={{ marginTop: 4, marginBottom: 4 }} />
      ) : (
        <DialogContent>
          {errorMessage && (
            <Typography color="error" align="center">
              {errorMessage}
            </Typography>
          )}

          {priceRangeError && (
            <Typography color="error" align="center">
              {adjustWageDialogI18n('price_range_fail')}
            </Typography>
          )}

          <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={3}>
            <Typography variant="subtitle1" color="textSecondary">
              {adjustWageDialogI18n('wage_per_hour')}
            </Typography>
            <NumberTextField
              inputMode="numeric"
              value={wage.toString()}
              onNumberChange={customAmountBidPriceHandler}
              onBlur={() => setWage(parseFloat(wage.toFixed(2)))}
              InputProps={{
                inputProps: {
                  pattern: '[0-9]*',
                  sx: { maxWidth: 64, textAlign: 'center' },
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton disabled={wage <= initialWage} onClick={decrementHandler}>
                      <Remove />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={incrementHandler}>
                      <Add />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Typography variant="caption" color="textSecondary" align="center" display="block" mt={2}>
            {adjustWageDialogI18n('description')}
          </Typography>

          <Typography variant="h5" color="textSecondary" align="center" display="block">
            {adjustWageDialogI18n('working_hour', { hour: workingHour })}
          </Typography>

          <Typography variant="h5" color="textSecondary" align="center" display="block">
            {adjustWageDialogI18n('paid_total', { total: numeral(totalWage).format('0,0.00') })}
          </Typography>

          <Typography variant="body2" color="textSecondary" align="center" mt={2}>
            {adjustWageDialogI18n('remark')}
          </Typography>
        </DialogContent>
      )}

      <DialogActions>
        <Button fullWidth variant="contained" color="primary" onClick={submitHandler} disabled={wage <= initialWage}>
          {adjustWageDialogI18n('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdjustWageDialog;
