import React from 'react';

import { gql, useLazyQuery } from '@apollo/client';

type ChainPermissions = {
  profileEdit: boolean;
  restaurantCreate: boolean;
};

type RestaurantPermissions = {
  jobCreate: boolean;
};

interface ChainPermissionData {
  permissions: {
    chain: ChainPermissions;
  };
}

interface RestaurantPermissionData {
  permissions: {
    restaurant: RestaurantPermissions;
  };
}

interface PermissionData {
  permissions: {
    chain: ChainPermissions;
    restaurant: RestaurantPermissions;
  };
}

const getChainPermissionGql = gql(`
  query getChainPermission($chainId: ID!) {
    permissions {
      chain(chainId: $chainId) {
        profileEdit
        restaurantCreate
      }
    }
  }
`);

const getRestaurantPermissionGql = gql(`
  query getRestaurantPermission($restaurantId: ID!) {
    permissions {
      restaurant(restaurantId: $restaurantId) {
        jobCreate
      }
    }
  }
`);

const getUserPermissionGql = gql(`
  query getPermission($chainId: ID!, $restaurantId: ID!) {
    permissions {
      chain(chainId: $chainId) {
        profileEdit
        restaurantCreate
      }
      restaurant(restaurantId: $restaurantId) {
        jobCreate
      }
    }
  }
`);

const useGetPermissions = () => {
  const [getChainPermissionQuery] = useLazyQuery<ChainPermissionData>(getChainPermissionGql, {
    fetchPolicy: 'network-only',
  });

  const [getRestaurantPermissionQuery] = useLazyQuery<RestaurantPermissionData>(getRestaurantPermissionGql, {
    fetchPolicy: 'network-only',
  });

  const [getUserPermissionQuery] = useLazyQuery<PermissionData>(getUserPermissionGql, {
    fetchPolicy: 'network-only',
  });

  const getChainPermissions = React.useCallback(
    async (chainId: string) => {
      const { data } = await getChainPermissionQuery({ variables: { chainId } });
      return data?.permissions;
    },
    [getChainPermissionQuery],
  );

  const getRestaurantPermissions = React.useCallback(
    async (restaurantId: string) => {
      const { data } = await getRestaurantPermissionQuery({ variables: { restaurantId } });
      return data?.permissions;
    },
    [getRestaurantPermissionQuery],
  );

  const getUserPermissions = React.useCallback(
    async (chainId: string, restaurantId: string) => {
      const { data } = await getUserPermissionQuery({ variables: { chainId, restaurantId } });
      return data?.permissions;
    },
    [getUserPermissionQuery],
  );

  return { getChainPermissions, getRestaurantPermissions, getUserPermissions };
};

export default useGetPermissions;
